
import React from "react"
import Type from "../../components/typography/Type"


const JourneyCard = (props) => {	
	
const screenshot = props.screenshot; 
const org = props.org; 
const title = props.title;
const url = props.url;
		
	
	return (
		
		<a href={url}>
		<div className="">

			<div className="p-2 h-full border-blue/50 border-2 z-0 hover:border-white hover:border-blue relative">
			<img
				src={screenshot}
				alt={title}
				className="w-full mb-2"
				/>
			<Type type="p6" nopadding="yes">{org}</Type>
			<Type type="h6" nopadding="yes">{title}</Type>
		  </div>
			</div>
		  </a> 
		
	);
};

export default JourneyCard;
