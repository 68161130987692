import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import SearchNav from "../../components/search/SearchNav"
import JourneyCard from "../../components/cards/JourneyCard"

import { Router } from "@reach/router"
import { getUser, isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"


const Journeys = ({ data, pageContext }) => (

<Layout>
<Container>

  {isLoggedIn() ? (
  <>
 
 <div className="flex flex-col lg:flex-row lg:space-x-16">
   <div className="w-full lg:w-3/12">
     <SearchNav/>
   </div>
   <div className="w-full lg:w-9/12 mt-12 lg:mt-0">
   <Type type="h2">Latest journeys</Type>
     <div className="grid grid-cols-2 lg:grid-cols-3 gap-8">
       {data.allAirtable.edges.map((edge) => (
        <JourneyCard
        url={edge.node.data.Path}
        screenshot={edge.node.data.ScreenshotURLHero}
        org={edge.node.data.OrganisationTxt}
        title={edge.node.data.JourneyName}
        />
        ))}
     </div>
   </div>
 </div>

 
  </>
) : (
  <>
    <LoginPrompt/>
  </>
)}




</Container>
</Layout>


)

export const Head = () => <title>Journeys</title>

export default Journeys

// query airtable for the Title and Path of each record,
// filtering for only records in the Sections table.
// sort: {fields: data___Order, order: ASC}
    
export const query = graphql` 
query ($skip: Int!, $limit: Int!) {
  allAirtable (
    filter: {queryName: {eq: "Journeys"}}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        data {
          JourneyName
          Path
          OrganisationTxt
          JourneyCategoryTxt
          ScreenshotURLHero
        }
      }
    }
  }
}
`
